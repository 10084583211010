
import { Formik } from "formik";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { useMutation } from "@tanstack/react-query";
import { PostLeakReply } from "../api";
import { useParams } from "react-router-dom";

const initialValues = {
    message: "",
}



const ReplyALeak = ({ setIsOpen, refetch }) => {
    const { leakId } = useParams()

    const { mutate, isLoading } = useMutation(PostLeakReply, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            setIsOpen(false);
            refetch();
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),
    });
    return (
        <>
            <div className="reply-container">
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        mutate({
                            leakId,
                            message: values.message,
                        })
                    }}

                >
                    {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="input-comment-text-div">
                                <input
                                    className="input-comment-text"
                                    type="text"
                                    name="message" // The name should match the key for the API
                                    placeholder="Post your reply"
                                    value={values.message} // The value passed to the API
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <button className="submit-reply" type="submit">Reply</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    )
}
export default ReplyALeak;