import './App.css';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import VerifyEmail from './pages/VerifyEmail';
import InputPassword from './pages/InputPassword';
import WhoYouAre from './pages/WhoYouAre';
import ProfileOne from './pages/ProfileOne';
import AllSet from './pages/AllSet';
import Navbar from './nav/Navbar';
import MyQueries from './pages/MyQueries';
import AccountSetting from './pages/AccountSetting';
import Notifications from './pages/Notifications';
import ProfileInfo from './pages/ProfileInfo';
import MainLayout from './layout/MainLayout';
import AuthLayout from './layout/AuthLayout';
import ErrorPage from './pages/ErrorPage';
import ProtectedRoutesProvider from './context/ProtectedRoutes';
import QueryDetails from './pages/QueryDetails';
import ResetEmail from './pages/ResetEmail';
import ConfirmSecurityQuestion from './pages/ConfirmSecurityQuestion';
import SetNewPassword from './pages/SetNewPassword';
import ProfileSearch from './pages/ProfileSearch';
import Settings from './pages/Settings';
import Loader from './components/Loader';
import { Suspense } from 'react';
import MyLeaks from './pages/MyLeaks';
import LeakDetails from './pages/LeakDetails';


function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <SignIn />,
        },
        {
          path: "login",
          element: <SignIn />,
        },
        {
          path: "register",
          element: <SignUp />,
        },
        {
          path: "verify",
          element: <VerifyEmail />,
        },
        {
          path: "inputpassword",
          element: <InputPassword />,
        },
        {
          path: "profileone",
          element: <ProfileOne />,
        },
        {
          path: "allset",
          element: <AllSet />,
        },
        {
          path: "login/resetemail",
          element: <ResetEmail />,
        },
        {
          path: "resetpassword",
          element: <ConfirmSecurityQuestion />,
        },
        {
          path: "newpassword",
          element: <SetNewPassword />,
        },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "queries",
          element: <MyQueries />,
        },
        {
          path: "queries/:queryId",
          element: <QueryDetails />,
        },
        {
          path: "whoweare",
          element: <WhoYouAre />,
        },
        {
          path: "accountsetting",
          element: <AccountSetting />,
        },
        {
          path: "nav",
          element: <Navbar />,
        },
        {
          path: "notification",
          element: <Notifications />,
        },
        {
          path: "profileinfo",
          element: <ProfileInfo />,
        },
        {
          path: "profilesearch",
          element: <ProfileSearch />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "myleaks",
          element: <MyLeaks />,
        },
        {
          path: "leaksdetails/:leakId",
          element: <LeakDetails />,
        },
      ]
    },
    {
      path: "*",
      element: <ErrorPage />,
    },

  ]);

  return (
    <Suspense fallback={<Loader />}>
      <ProtectedRoutesProvider>
        <RouterProvider router={router} />
      </ProtectedRoutesProvider>
    </Suspense>
  );
}

export default App;
