import { useParams } from "react-router-dom";
// import calender from "../assets/calendar-edit.png";
// import archieve from "../assets/archive-tick.png";
import queryPerson from "../assets/emma.svg";
import paulPerson from "../assets/paul-picture11.svg";
// import clock from "../assets/clock.svg";
import bookmarkdetails from "../assets/bookmark-details.svg";
import danger from "../assets/Danger.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
// import { ReplyPitch, getQueryDetails } from "../api";
import { getMyLeakDetails, ReplyLeak } from "../api";
import { parseISO, format, formatDistanceToNow } from 'date-fns';
import { useState } from "react";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { Formik } from "formik";
// import MainNavbar from "../nav/MainNavbar";

import Modal from "../modal/Modal";
import ReactPaginate from "react-paginate";
import bookmarkinged from "../assets/booknownowimg.svg"
import { Helmet } from "react-helmet";
// import Footer from "../footer/Footer";
import { Navbar } from "react-bootstrap";
import ReplyALeak from "../modal/ReplyALeak";
import { FaCaretSquareRight, FaFileArchive } from "react-icons/fa";
import jpeglevel from "../assets/greenjpeg.png"
import pdflevel from "../assets/Filetypes.png"
import bluepng from "../assets/bluepng.png"
import blackjpg from "../assets/blackjpg.png";

// import ReplyComponent from "./ReplyComponent";





const LeakDetails = () => {
    const [isAddLeakModalOpen, setIsAddLeakModalOpen] = useState(false);


    let storedObject = localStorage.getItem('user');
    // const [comments] = useState({});

    let firstname = '';
    let lastname = '';


    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject?.first_name;
        lastname = parsedObject?.last_name;
    } else {
        // console.log('Object not found in local storage');
    }


    const [currentPage, setCurrentPage] = useState(1); //
    const resultsPerPage = 2;

    const { leakId } = useParams()
    // console.log("wdwdwdd", leakId)

    const { data, refetch } = useQuery({
        queryKey: ["getMyLeakDetails", leakId, currentPage],
        queryFn: () => getMyLeakDetails(leakId, currentPage),
        suspense: true,
    });


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const { mutate } = useMutation(ReplyLeak, {
        onSuccess: () => {
            refetch(); // Refresh the chat thread after a successful reply
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });

    const handleReplySubmit = (values, chatThreadId, resetForm) => {
        // const payload = {
        //     message: values.message,
        //     chat_id: chatThreadId, // Include chat_thread_id in the payload
        // };
        mutate({
            leakId,
            message: values.message,
            chat_id: chatThreadId,
        }, {
            onSuccess: () => {
                resetForm(); // Reset the form after submission
            },
        });
    };



    return (
        <>
            <div>
                <Helmet>
                    <title>Connected Newsroom | Leak Details</title>
                </Helmet>
                <Navbar />
                <div className="container">
                    <div className="for-flex">
                        <h2 className="good-greeting">Hello, {firstname}</h2>
                        <button className="create-a-query" onClick={() => setIsAddLeakModalOpen(true)}>Reply Leak</button>
                    </div>
                    <div className="query-details-container">
                        <div className="extended-div-container">
                            <div className="extended-admin">
                                {/* <h4 className="extended-admin-text">{data?.data?.title}</h4> */}
                                <h4 className="extended-admin-text">{data?.data?.title}</h4>
                            </div>
                            <div className="extended-mainline">
                                <div className="extended-line">
                                    {/* {data?.data?.is_bookmarked ? (<img src={bookmarkinged} alt="" className="" />) : (<img src={bookmarkdetails} alt="" className="" />)} */}
                                    {/* <img src={danger} alt="" className="mx-3" /> */}
                                </div>
                            </div>
                        </div>

                        <div className="person-detail">
                            {/* {data?.data?.journalist?.avatar ? (<img className="extended-img-person" src={data?.data?.journalist?.avatar} alt="" />) : (<img className="extended-img-person" src={queryPerson} alt="" />)} */}
                            {data?.data?.source_avatar ? (<img className="extended-img-person" src={data?.data?.source_avatar} alt="" />) : (<img className="mr-2" src={paulPerson} alt="" />)}

                            <h2 className="person-image">{data?.data?.source_first_name} {data?.data?.source_last_name}</h2>
                        </div>
                        <h6 className="status-status">Anonymous</h6>
                        <p className="query-sub-description">{data?.data?.is_anonymous ? (<p>Yes</p>) : (<p>No</p>)}</p>
                        <div className="awaiting-review">
                            {/* <h6 className="awaiting-text">{data?.data?.status}</h6> */}
                        </div>
                        <h3 className="query-description">Leak Description</h3>
                        {/* <h6 className="query-sub-description">{data?.data?.description}</h6> */}
                        <h6 className="query-sub-description">{data?.data?.description}</h6>
                        {Array.isArray(data?.data?.tags) && data?.data?.tags.length > 0 ? (
                            <div>
                                <h3 className="query-description">Category</h3>
                                <div className="query-sub-description spread-them"> {data?.data?.tags.map((tag, index) => (
                                    <div> <p className="query-sub-description">{tag},</p></div>
                                ))}</div>
                            </div>

                        ) : null}
                        {/* {Array.isArray(data?.data?.audience) && data?.data?.audience.length > 0 ? (
                            <div className="query-sub-description"> {data?.data?.audience.map((audi, index) => (
                                <div>
                                    <h3 className="query-description">Audience</h3>
                                    <p className="query-sub-description">{audi}</p>
                                </div>
                            ))}
                            </div>
                        ) : null} */}
                        <div className="query-sub-description"> {data?.data?.outlets.map((out, index) => (
                            <div>
                                <h3 className="query-description">Media Outlet</h3>
                                <p className="query-sub-description">{out}</p>
                            </div>
                        ))}
                        </div>
                        <h6 className="categories-text">Attachments</h6>

                        <div className="file-format">
                            {data?.data?.attachments.map((attachment, idx) => {
                                const extension = attachment.split('.').pop().toLowerCase();

                                return (
                                    <div key={idx} >
                                        {extension === "pdf" && (
                                            <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                <img className="tailwind" src={pdflevel} alt="PDF" />
                                            </a>
                                        )}
                                        {extension === "jpeg" && (
                                            <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                <img className="tailwind" src={jpeglevel} alt="JPEG" />
                                            </a>
                                        )}
                                        {extension === "jpg" && (
                                            <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                <img className="tailwind" src={blackjpg} alt="JPG" />
                                            </a>
                                        )}
                                        {extension === "png" && (
                                            <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                <img className="tailwind" src={bluepng} alt="PNG" />
                                            </a>
                                        )}
                                        {extension === "mp4" && (
                                            <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                <div className="video-play-icon">
                                                    <img className="tailwind" src={<FaCaretSquareRight className="tailwind" />} alt="Video" />

                                                </div>
                                            </a>
                                        )}
                                        {extension === "tmp" && (
                                            <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                <div className="video-play-icon1">
                                                    <FaFileArchive className="tailwind" /> {/* Icon for ZIP files */}
                                                </div>
                                            </a>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        {/* <h3 className="query-description">Attachment(s)</h3> */}
                    </div>


                    <h3 className="query-description">Leak <span className="query-description">{data?.data?.responses}</span></h3>
                    {data?.data?.chat_thread?.data?.map((chat, index) => (
                        <div key={index} className="query-details-container1">
                            <div className="response-div">
                                {
                                    chat?.journalist_avatar !== null ? (
                                        <img className="mr-2" src={chat?.journalist_avatar} alt="" />
                                    ) : chat?.source_avatar !== null ? (
                                        <img className="mr-2" src={chat?.source_avatar} alt="" />
                                    ) : (
                                        <img className="mr-2" src={paulPerson} alt="" />
                                    )
                                }
                                <div>
                                    {/* <h3 className="paul-name"> {chat.journalist_last_name}</h3> */}
                                    {
                                        chat?.journalist_first_name !== null ? (
                                            <h3 className="paul-name"> {chat?.journalist_first_name}</h3>
                                        ) : chat?.source_first_name !== null ? (
                                            <h3 className="paul-name"> {chat?.source_first_name}</h3>
                                        ) : (
                                            <h3 className="paul-name"> Connected Newsroom</h3>
                                        )
                                    }
                                    <h6 className="paul-subname">{formatDistanceToNow(parseISO(chat?.created_at), { addSuffix: true, includeSeconds: false })}</h6>
                                </div>
                            </div>
                            <h6 className="paul-subname1">{chat.message}</h6>
                            <ChatComponent
                                leakId={leakId}
                                onReplySubmit={handleReplySubmit}
                                replies={chat?.chat_thread}
                                chatThreadId={chat.id}
                            />

                        </div>
                    ))}
                    <ReactPaginate
                        previousLabel={'Previous'}
                        previousClassName="previous-class"
                        nextLabel={'Next'}
                        nextClassName="next-class"
                        pageCount={data?.data?.chat_thread?.total / 5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active-paginate'}
                        renderOnZeroPageCount={null}
                        breakLabel="..."
                    />

                </div>



                {/* <Footer /> */}
            </div>



            <Modal isOpen={isAddLeakModalOpen} setIsOpen={setIsAddLeakModalOpen} title="Reply this Leak" size="modal-normal">
                <ReplyALeak setIsOpen={setIsAddLeakModalOpen} refetch={refetch} />
            </Modal>
        </>
    )
}





const ChatComponent = ({ leakId, chatThreadId, onReplySubmit, replies }) => {
    const [showInput, setShowInput] = useState(false);

    return (
        <div>
            {showInput ? (
                <div className="reply-container">
                    <Formik
                        initialValues={{ message: '' }}
                        onSubmit={(values, { resetForm }) => {
                            // console.log('Reply submitted:', values.message); // Log the message here
                            onReplySubmit(values, chatThreadId, resetForm);
                            setShowInput(false);
                        }}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="input-comment-text-div">
                                    <input
                                        className="input-comment-text"
                                        type="text"
                                        name="message" // The name should match the key for the API
                                        placeholder="Post your reply"
                                        value={values.message} // The value passed to the API
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <button className="submit-reply" type="submit">Reply</button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            ) : (
                <p className="reply" onClick={() => setShowInput(!showInput)}>Reply</p>
            )}
            <div>
                {replies?.map((reply, index) => (
                    <div className="ml-5 leak-replies" key={index}>
                        {
                            reply?.journalist_avatar !== null ? (
                                <img className="leak-reply-name" src={reply?.journalist_avatar} alt="" />
                            ) : reply?.source_avatar !== null ? (
                                <img className="leak-reply-name" src={reply?.source_avatar} alt="" />
                            ) : (
                                <img className="mr-2" src={paulPerson} alt="" />
                            )
                        }

                        <div>
                            <p className="leak-reply-name">
                                {
                                    reply?.journalist_first_name !== null ? (
                                        <h3 className="leak-reply-name"> {reply?.journalist_first_name}</h3>
                                    ) : reply?.source_first_name !== null ? (
                                        <h3 className="leak-reply-name"> {reply?.source_first_name}</h3>
                                    ) : (
                                        <h3 className="leak-reply-name"> Connected Newsroom</h3>
                                    )
                                }
                            </p>
                            <h6 className="paul-subnamedate">{formatDistanceToNow(parseISO(reply?.created_at), { addSuffix: true, includeSeconds: false })}</h6>
                            <p className="leak-reply-subname">{reply.message}</p>

                        </div>

                    </div>
                ))}

            </div>
        </div>
    );
};

export default LeakDetails;